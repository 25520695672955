<template>
  <v-form @submit.prevent="submitPage">
    <v-container class="formPage py-8">
      <v-row>
        <v-col cols="12">
          <h2 class="text-h4">
            {{ isModeNew ? $locale.addPage : $locale.editPage }}
          </h2>
        </v-col>
        <v-col cols="12">
          <v-text-field
            :label="$locale.inputPageName"
            outlined
            hide-details="auto"
            v-model="page.name"
          />
        </v-col>
        <v-col cols="12">
          <ckeditor
            v-if="isPageLoaded"
            :editor="editor"
            v-model="page.content"
            :config="editorConfig"
          ></ckeditor>
        </v-col>
        <v-col cols="12">
          <v-btn
            x-large
            depressed
            color="primary"
            type="submit"
            :loading="isBeingSaved"
          >
            {{ isModeNew ? $locale.add : $locale.saveChanges }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="snack" :timeout="5000" :color="snackColor">
      {{ snackText }}

      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" @click="snack = false" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-form>
</template>

<script>
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import '@/assets/styles/ckeditor.scss';
import {
  GET_PAGE,
  ADD_PAGE,
  EDIT_PAGE,
  CLEAR_PAGE_STATE,
} from '@/store/pages/types';
import { TOGGLE_PAGE_LOADER } from '@/store/app/types';

export default {
  name: 'PopupFormPage',
  metaInfo() {
    const title = this.isModeNew ? this.$locale.addPage : this.$locale.editPage;
    return {
      title,
    };
  },
  data: () => ({
    editorKey: '',
    editor: Editor,
    page: {
      name: '',
      content: '',
    },
    isBeingSaved: false,
    snack: false,
    snackColor: '',
    snackText: '',
    isPageLoaded: false,
  }),
  props: {
    id: {
      type: [String, Number],
    },
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      this.clearPageState();
      this.isPageLoading = true;

      if (!this.isModeNew) {
        await this.getPage();
      }

      this.$store.dispatch(TOGGLE_PAGE_LOADER, false);
      this.isPageLoaded = true;
    },
    async clearPageState() {
      this.$store.dispatch(CLEAR_PAGE_STATE);
    },
    async getPage() {
      this.$store.dispatch(TOGGLE_PAGE_LOADER, true);
      await this.$store.dispatch(GET_PAGE, this.id);

      this.page.name = this.pageData.name;
      this.page.content = this.pageData.content;
    },
    async submitPage() {
      // this.$v.$touch();
      // if (this.$v.$invalid) {
      //   return false;
      // }

      this.isBeingSaved = true;

      const page = { ...this.page };

      if (this.isModeNew) {
        try {
          await this.$store.dispatch(ADD_PAGE, page);
          this.snack = true;
          this.snackColor = 'success';
          this.snackText = this.$locale.successPageAdd;

          this.page.name = '';
          this.page.content = '';
        } catch (e) {
          this.snack = true;
          this.snackColor = 'error';
          this.snackText = this.$locale.errorBasic;
        }
      } else {
        try {
          page.id = this.id;
          await this.$store.dispatch(EDIT_PAGE, page);
          this.snack = true;
          this.snackColor = 'success';
          this.snackText = this.$locale.successChangesSaved;
        } catch (e) {
          this.snack = true;
          this.snackColor = 'error';
          this.snackText = this.$locale.errorBasic;
        }
      }

      this.isBeingSaved = false;
      window.scrollTo(0, 0);
    },
  },
  computed: {
    pageData() {
      return this.$store.getters.page;
    },
    isModeNew() {
      return this.$route.name === 'AddPage';
    },
    editorConfig() {
      return {
        language: 'uk',
        placeholder: this.$locale.inputArticleText,
        toolbar: {
          items: [
            'bold',
            'italic',
            'underline',
            '|',
            'alignment',
            '|',
            'fontSize',
            'fontColor',
            '|',
            'bulletedList',
            'numberedList',
            'outdent',
            'indent',
            '|',
            'link',
            'insertImage',
            'mediaEmbed',
            'CKFinder',
            'blockQuote',
            'insertTable',
            'horizontalLine',
            '|',
            'undo',
            'redo',
          ],
        },
        image: {
          toolbar: [
            'toggleImageCaption',
            'imageStyle:inline',
            'imageStyle:wrapText',
            'imageStyle:breakText',
          ],
        },
        table: {
          contentToolbar: [
            'tableColumn',
            'tableRow',
            'mergeTableCells',
            // "tableCellProperties",
            // "tableProperties",
          ],
        },
        link: {
          addTargetToExternalLinks: true,
        },
        ckfinder: {
          uploadUrl: process.env.VUE_APP_CKFINDER_UPLOAD_URL,
        },
        mediaEmbed: {
          previewsInData: true,
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.formPage {
  max-width: 900px;
}
</style>
